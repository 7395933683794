import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on workstation security (for HIPAA).`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to provide guidance for workstation
security for Data Migrators workstations in order to ensure the
security of information on the workstation and information the
workstation may have access to. Additionally, the policy provides
guidance to ensure the requirements of the HIPAA Security Rule
“Workstation Security” Standard 164.310(c) are met.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all Data Migrators employees, contractors,
workforce members, vendors and agents with a Data Migrators-owned or
personal-workstation connected to the Data Migrators network.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`Appropriate measures must be taken when using workstations to ensure the
confidentiality, integrity and availability of sensitive information,
including protected health information (PHI) and that access to
sensitive information is restricted to authorized users.`}</p>
    <p>{`Workforce members using workstations shall consider the sensitivity
of the information, including protected health information (PHI) that
may be accessed and minimize the possibility of unauthorized access.`}</p>
    <p>{`Data Migrators will implement physical and technical safeguards
for all workstations that access electronic protected health information
to restrict access to authorized users.`}</p>
    <p>{`Appropriate measures include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Restricting physical access to workstations to only authorized
personnel.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Securing workstations (screen lock or logout) prior to leaving area
to prevent unauthorized access.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Enabling a password-protected screen saver with a short timeout
period to ensure that workstations that were left unsecured will be
protected. The password must comply with Data Migrators `}<em parentName="p">{`Password
Policy`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Complying with all applicable password policies and procedures. See
Data Migrators `}<em parentName="p">{`Password Policy`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensuring workstations are used for authorized business purposes
only.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Never installing unauthorized software on workstations.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Storing all sensitive information, including protected health
information (PHI) on network servers`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Keeping food and drink away from workstations in order to avoid
accidental spills.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Securing laptops that contain sensitive information by using cable
locks or locking laptops up in drawers or cabinets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Complying with the `}<em parentName="p">{`Portable Workstation Encryption Policy`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Complying with the Baseline `}<em parentName="p">{`Workstation Configuration Standard`}</em></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Installing privacy screen filters or using other physical barriers
to alleviate exposing data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensuring workstations are left on but logged off in order to
facilitate after-hours updates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Exit running applications and close open documents`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ensuring that all workstations use a surge protector (not just a
power strip) or a UPS (battery backup).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If wireless network access is used, ensure access is secure by
following the `}<em parentName="p">{`Wireless Communication policy`}</em></p>
      </li>
    </ul>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Password Policy`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Portable Workstation Encryption Policy`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wireless Communication policy`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Workstation Configuration Standard`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://www.hipaasurvivalguide.com/hipaa-regulations/164-310.php"
          }}>{`HIPPA 164.210`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "http://abouthipaa.com/about-hipaa/hipaa-hitech-resources/hipaa-security-final-rule/164-308a1i-administrative-safeguards-standard-security-management-process-5-3-2-2/"
          }}>{`About HIPPA`}</a></p>
      </li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      